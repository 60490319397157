p{letter-spacing: 1px;font-weight: 300;}
*{margin: 0; color:#fff}
.logo-homepage {height: 45px;}
.navbar-light .navbar-brand {color: #fff !important; display: flex; align-items: center;}
.navbar-collapse {flex-grow: initial !important;}
.navbar-light .navbar-nav .nav-link {color: #747AA8 !important;}
a.navbar-brand span {font-size: 14px; margin: 18px 0 0 10px;}
p.best-deal { color: #fff !important; letter-spacing: 5px; font-size: 16px; margin: 0; font-weight: 300; text-transform: uppercase;}
.banner-content h1 {font-weight: 700; color:#fff; font-size:80px;}
.banner-content h1 span {background: -webkit-linear-gradient(91.53deg, #7C6ADD 2.89%, #617EEB 97.48%) ;  -webkit-background-clip: text; -webkit-text-fill-color: transparent;}
.buy-btn-row p {margin-bottom: 0;margin-right: 10px;color: #fff; font-weight: 700;}
.buy-btn-row {display: flex; align-items: center;margin-top: 60px;}
.buy-btn-row button {margin-right: 10px; margin-bottom: 10px;}
.banner {width: 100%;display: flex;align-items: center;padding: 100px 0; position: relative; margin-top:50px;}
.banner-content .content {width:435px;}
.banner-content p {color: #747AA8;}
.buy-btn-row p {color: #fff;}
img.banner-img {position: absolute; right: 0;  width:65%; z-index:-1;}
.banner svg {position: absolute; width: 25%; z-index: -1;}
.gr-button{margin-top: 10px;}  

.axiforma-font{
  font-family: 'Axiforma', sans-serif;
}
.chart-box{
  margin-bottom: 10px;
}

.main-page-heading {text-align: center; width: 100%;}
.main-page-heading p {letter-spacing: 5px; font-size: 10px; color:#fff; text-transform: uppercase;}
.main-page-heading h2 {font-weight: 700; font-size:50px;color: #e8e8e8;}
.main-page-heading {text-align: center; width: 100%; margin: 0 0 50px;}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
  color: #fff !important; background-color: transparent !important;  border: 0;  border-bottom:0 !important;}
.nav-tabs { border-bottom: 0 !important;   text-align: center;   justify-content: center;}
.nav-link{color:#747AA8 !important; margin-bottom:0 !important; border:0 !important; position: relative;}
.nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover { border:0 !important; color:#fff }
.nav-link.active:after  {border-bottom:1px solid red; bottom: 0; position:absolute; content:''; width:100%;}
.nav-link.active:after {border-bottom: 2px solid #747AA8;bottom: 0;position: absolute; content: ''; width: 70%; left: 0; right: 0; margin: auto;}
header .navbar-light .navbar-nav .nav-link {color: #747AA8 !important; font-size: 13px; letter-spacing: 1px;}


.all-deal-box.home-deal-box img{width: auto !important;}
.deal-box-inner {border: 1px solid #404881; border-radius: 10px;}
.about-joindeal p {margin: 0; font-size:13px;  text-align: center; width:50%;background: linear-gradient(91.95deg, #8169DD 0%, #5374EB 98.35%);  -webkit-background-clip: text; -webkit-text-fill-color: transparent;}
.brdr-ab {border-right: 2px solid #404881;}
.about-joindeal {display: flex;justify-content:center;padding: 15px 0;    background: linear-gradient( 176.07deg , #2A2E58 4.63%, #31396E 91.28%);}
.cnt-box {padding: 20px;}
.all-deal-box.home-deal-box {padding: 0;}
.all-deal-row { display: flex;}
.tab-content { margin-top: 50px;}
.all-deal-box.home-deal-box {padding: 0;box-shadow: 0px 4px 22px rgb(33 39 76 / 70%); margin:0 10px;}
.all-deal-box{border-radius:20px; overflow: hidden;}

.project-section {padding: 100px 0;}
.bulk-box .content p {font-size:18px;text-align: center; margin: 0 0 0; letter-spacing: 1px; font-weight:bold;}

.media-production {padding: 100px 0;}
.support-box .content {background:rgb(54 62 107); box-shadow: 0px 4px 40px rgb(0 0 0 / 12%); border-radius: 10px; padding: 20px;}
.support-box .content p {font-size: 14px; line-height: 26px;color: #747AA8; margin: 0;}
.support-box .content h5 {font-size: 18px; letter-spacing: 1px; margin: 20px 0 12px;}
.support-row {display: flex;flex-wrap: wrap; margin: -15px;}
.support-box {width: 50%; padding: 0 15px;}
.partnership-box {margin-top:70px;min-height: 250px; display: flex;}

.market-media-cnt .content .main-page-heading {text-align: left;}
.market-media-cnt .bottom-content p {color: #747AA8; font-size: 16px; margin: 0;}
.market-media-cnt .content .main-page-heading {text-align: left;  margin-bottom: 30px;}
.market-media-cnt .content {padding-left: 50px; position: relative;}

.media-left-graph-bg {position: absolute;width: 60%; z-index: -1;}
.media-left-graph-bg img {width: 100%;}
.svg-mp {position: absolute;}

.financial-box {display: flex;border: 1px solid #747AA8;padding: 40px 50px;align-items: center;border-radius: 10px;width: 75%; margin: auto;}
.financial-box .content p {margin: 0; font-size: 18px; letter-spacing: 1px; line-height: 32px;}
.financial-box .content {width: calc(100% - 55px); padding-left: 30px;}
.financial-seciton {position: relative;}
.financial-seciton img.right-ln {right: 0;position: absolute;width: 30%;  top: 61px;}

.technical-support {padding: 100px 0;}
.tech-support-box { background: rgba(128, 134, 207, 0.1);
  box-shadow: 0px 4px 40px rgb(0 0 0 / 12%); backdrop-filter: blur(10px);
  border-radius: 10px; padding: 25px 40px; position: relative; display: flex; align-items: center; margin:0 0 30px;}
.tech-support-box .icon {background: #2E3560;position: absolute;left: -21px;width: 40px;height: 40px; align-items: center;display: flex; justify-content: center; border-radius: 100%;}
.tech-support-left .content p {color: #747AA8;}
.tech-support-box.sup-border {background: transparent; border: 1px dotted #6f759b;}
.tech-support-box.sup-border .icon{border: 1px dotted #6f759b;}
.tech-support-box.sup-border .icon:after {
  content: '';
  width: 32px;
  position: absolute;
  height: 56px;
  border: black;
  left: -13px;
  display: inline-block;
  top: -14px;
  background: #2e3560;
}

.tech-support-box.sup-border svg{position: relative; z-index: 9;}
.tech-support-box .content p {color: #747AA8; font-size: 14px; line-height: 26px; letter-spacing: 1px; margin: 0;}
.tech-support-left .main-page-heading {text-align: left;}
.tech-support-left .content {padding-right: 70px;}
.advisory-box { border-radius: 10px; display: flex;
  border: 1px solid #4f5780;
  align-items: center; padding: 20px 30px; min-height:130px;}
.advisory-box:hover { background: rgba(128, 134, 207, 0.1);  box-shadow: 0px 4px 50px rgb(0 0 0 / 12%); border: none; }
.advisory-box .content {padding-left:30px;}
.advisory-box .content p{color:#fff; margin:0; font-size:18px; line-height:26px; letter-spacing:1px;}

.accordion-item {background:rgb(60 67 114) !important;box-shadow: 0px 4px 40px rgb(0 0 0 / 12%) !important;border-radius: 10px !important; margin:0 0 15px;}
button.accordion-button.collapsed {
  background: rgba(128, 134, 207, 0.1);
  box-shadow: 0px 4px 40px rgb(0 0 0 / 12%);
  border-radius: 10px !important;
  color: #fff;
  font-size: 18px;
}
.accordion-body { font-size: 14px; color: #747AA8; line-height: 26px;}
.accordion-button:not(.collapsed) {color: #fff !important;background-color: transparent !important;box-shadow: none !important; font-size: 18px;}
section.faq-section {padding: 100px 0; position: relative;}
.fc {position: absolute;right: 0; width: 50%; z-index:-1;}
.faq-section .bg-faq {position: absolute; z-index: -1; top: 25%; right: 0; width: 50%;}
.faq-content {width: 75%; margin: auto; font-family: 'Axiforma', sans-serif;}

.footer-contain {background:#21244c;display: flex;justify-content:space-between; border-radius: 50px;align-items: center;padding: 20px 20px;}
.footer-left ul {display: flex; padding: 0; margin: 0;}
.footer-right ul {display: flex; padding: 0; margin: 0;}
.footer-left ul li a {list-style: none;padding-right: 20px;font-size: 12px; color: #747AA8;}
.footer-right ul li  {list-style: none;padding-left: 20px;    display: flex; align-items: center;}
.footer-right ul li p {font-size: 12px; color: #747AA8; margin:0 0 0 ;} 
.footer-right {display: flex; align-items: center;}
.bottom-footer {font-size: 12px;text-align: center;color: #747AA8; font-weight: normal; margin:30px 0;   letter-spacing: 1px;}
.footer-left li {list-style: none;}
.footer-right li svg {height:15px;}

.book-slider-main:before { position: absolute; background: linear-gradient(270deg, rgba(46, 52, 95, 0) -68.04%, #2E3560 82.47%);  left: 0;
  content: '';  width: 20%;  height: 100%;  z-index: 9;
}
.book-slider-main:after { position: absolute; background:linear-gradient(90deg, rgba(46, 52, 95, 0) -68.04%, #2E3560 82.47%);  right: 0;
  content: '';  width: 20%;  height: 100%;  z-index: 9; top:0;
}
.book-slider-main{position: relative;}

.partner-section {position: relative;padding-bottom: 100px;}
.partner-section .bg {position: absolute;left: 0;top: -50px;width: 33%;z-index: -1;}
.partner-slider:before {
  top: 0;  position: absolute;
  background: linear-gradient(270deg, rgba(46, 52, 95, 0) -68.04%, #2E3560 98.47%);
  left: 0;  content: '';  width: 48%;  height: 100%;  z-index: 9;
}
.partner-slider:after {top:0; position: absolute; background:linear-gradient(90deg, rgba(46, 52, 95, 0) -68.04%, #2E3560 82.47%);  right: 0;content: '';  width:37%;  height: 100%;  z-index: 9; top:0;}
.partner-slider .item img {width:50% !important; margin: auto;}
.partner-slider {margin-top: 100px;}
.partner-section .main-page-heading {position: relative;z-index: 99;}
.partner-section  .owl-carousel.owl-drag .owl-item { height: 150px;align-items: center;display: flex;}

.bulk-graph { width: 100%; display: flex; align-items: center; justify-content: center; margin:0 0 20px;}
.bulk-graph p {position: absolute;color: #607DEA;font-size: 36px; font-weight: 700; letter-spacing: 1px;}
.orange-clr{ color:#FFD584 !important;} 

.book-slider-main .owl-nav {display: block !important; position: absolute; left: 0; right: 0; bottom: -80px;}
.book-slider-main  .owl-carousel .owl-nav button.owl-prev{background: url(../assets/images/left.png) ; width:52px;background-repeat: no-repeat !important; height:10px;}
.book-slider-main  .owl-carousel .owl-nav button.owl-next{background: url(../assets/images/right.png);width:52px; background-repeat: no-repeat !important; height:10px;}
.book-slider-main  .owl-carousel .owl-nav button span{display: none;}
.book-slider-main  .owl-carousel .owl-stage{padding:20px 0;}
.book-slider-main .owl-dots {display: none;}

.see-all-buttn button {width: auto; padding: 5px 65px;height: 45px; margin: 0; position: relative; z-index:999;}
.book-slider-main {width: 100%; text-align: center;}
.book-slider-main .owl-carousel .owl-nav button {margin: 0 120px;}
.see-all-buttn {margin-top: 44px;}
button.btn-main.hrd {  margin-left: 15px;}

.navbar-light .navbar-toggler {color: rgb(119 104 214); border-color: rgb(108 126 229) !important; border-width:2px;}
.navbar-light .navbar-toggler-icon {filter: brightness(0) invert(1);}
.accordion-button::after {opacity: 0.6 !important;}

@media (min-width: 1200px){
  .container, .container-lg, .container-md, .container-sm, .container-xl { max-width: 1140px !important;}
  }

@media screen and (max-width: 1199px)
{
.banner{margin-top:0;}
.svg-mp {z-index: -1; left: 0;}
.partner-slider:before, .partner-slider:after{display: none;}
.book-slider-main:after, .book-slider-main:before{display: none;}
.faq-content {width:100%;}
.financial-box{width:100%;}
}  

@media screen and (max-width:991px)
{
.banner{margin-top:0; padding: 50px 0 0;}
.container, .container-md, .container-sm { max-width: 90% !important;}
img.banner-img { width: 60%;}
.book-slider-main .owl-dots {display: none;}
.media-production {padding: 50px 0;}
.project-section {padding: 50px 0;}
.partner-slider {  margin-top: 50px;}
.footer-contain{flex-wrap: wrap;}
.me-auto.navbar-nav {background: #363e6b;padding: 20px;border-radius: 15px;position: absolute;z-index: 999;width: 90%; left: 0; right: 0;margin: auto;  top: 75px;}
button.btn-main.hrd { margin-left: 15px; display: none;}
.nav-link.active:after{display: none;}
.navbar-toggler:focus{box-shadow: none !important;}
}  

@media screen and (max-width: 767px)  
{
img.banner-img{display: none;}  
.banner-content .content { width: 100%; text-align: center;}
.buy-btn-row { margin-top:30px;flex-wrap: wrap; justify-content: center;}
.buy-btn-row p {width: 100%; margin-bottom:20px;}
.buy-btn-row button { min-width: 150px;}
.bulk-box {margin-bottom: 40px;}
.support-box {width: 100%; text-align: center;}
.partnership-box {margin-top: 30px;min-height: auto;}
.market-media-cnt {margin-top: 50px;}
.market-media-cnt .content .main-page-heading {text-align: center;}
.market-media-cnt .content {padding-left: 0px; position: relative;}
.market-media-cnt .bottom-content p{text-align: center;}
.financial-box .icon {width: 100%;text-align: center; margin-bottom: 20px;}
.financial-box {flex-wrap: wrap; justify-content: center;}
.financial-box .content {width: 100%; padding-left: 0; text-align: center;}
.tech-support-left .content {padding-right: 0;text-align: center;}
.tech-support-left .main-page-heading {text-align: center;}
.technical-support { padding: 50px 0;}
.main-page-heading {margin: 0 0 30px;}
.main-page-heading h2 {font-size: 32px;}
.advisory-box {margin: 0 0 30px;}
.advisory-box .icon {width: 100%; text-align: center; margin-bottom: 20px;}
.advisory-box {flex-wrap: wrap; justify-content: center; margin: 0 0 30px;}
.advisory-box .content {padding-left: 0; width: 100%; text-align: center;}
section.faq-section { padding:50px 0;}
.partner-section {padding-bottom: 50px;}
.footer-left {width: 100%;display: inline-block;text-align: center;}
.footer-left ul {display: flex;width: 100%; justify-content: center; margin-bottom: 10px;}
.footer-right{width:100%; flex-wrap: wrap; }
.footer-right ul {width: 100%;justify-content: center; margin:10px 0 0;}
.book-slider-main .owl-nav {position: relative; bottom: 0;}
.book-slider-main .owl-carousel .owl-nav button {margin: 0 30px;}
.banner-content h1 {font-weight: 700; color: #fff;  font-size: 44px;}
}

/*Custom accordion for FAQ */
.accordion {
  margin-top: 15px;
  padding: 18px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  transition: 0.4s;
  background: #3c4372!important;
  box-shadow: 0 4px 40px rgba(0,0,0,.12)!important;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.show{
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.accordion:after {
  content: '\002B';
  color: #fff;
  font-weight: bold;
  float: right;
  margin-left: 5px;
}

.show:after {
  content: "\2212";
}

.panel {
  padding: 0 18px;
  background-color: #3c4372;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.panel p{
  color: #747aa8;
  font-size: 14px;
  line-height: 26px;
}

.white-btn {height:35px;font-size:13px !important;background: transparent !important; border: 0 !important; 
  position: relative; letter-spacing: 1px; padding: 5px 30px; color: #fff;}
  .white-btn:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 15px;
    padding: 2px;
    background: linear-gradient(103deg, #e3dbff,#607DEA);
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out;
    mask-composite: exclude;
}