@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body{background:rgba(46, 53, 96, 1) !important; box-sizing: border-box; text-decoration: none;font-family: 'Source Sans Pro', sans-serif !important;}
a{text-decoration: none !important;}
*{margin: 0; padding:0;}
p{margin-bottom: 0;}
.tab-row a {
  padding: 0 0 10px 0;
  color: #747AA8;
  font-size: 13px;
  font-weight: 500;
  text-decoration: none;
  margin-right: 15%;
  letter-spacing: 1px;
}
.maine-content {margin-top: 20px;}
.tab-row { display: flex;  width: 100%;  margin:40px 0 20px;}
.user-name-row img {width: 30px; height: 30px; border-radius:10px;margin-right: 10px;}
.row-box { display: flex;  margin-top:0;  width: 100%; align-items: center;}
.row-box p {margin-bottom: 0;}
.user-name-row { display: flex;}
.button-row button {background: #35c8c2;border: 0;}
.tab-col  {padding: 20px;  margin-bottom: 30px;}
.button-row { border-top: 1px solid #58619d ;  margin: 15px -20px 0;   padding: 20px 20px 0;}
.text-heading {font-size: 12px; color: #747AA8;  font-weight: 500; margin-right: 15px;}
.amount {font-size: 12px; font-weight: 500; color: #fff; font-weight: 700;}
.font-18{font-size: 12px; font-weight: 500; color: #fff;font-weight: 700;}
.user-name-row p {color: #fff;font-size: 15px; margin-bottom: 0;}
.user-name-row .small-text {font-size: 13px; font-weight: 400; color: #747AA8; line-height: 8px;}
.user-name-row p span {color: #b5b5b5;}
.user-name-row p {font-weight:700;}
.Logo img {height:45px; }
.Logo {display: flex;align-items: center; margin-bottom:62px;}
.Logo p {margin: 14px 0 0 15px;color: #fff;letter-spacing: 2px;font-size: 14px;font-weight: 500;}


.btn {height: 45px; color: #fff; letter-spacing: 1px; border: 1px solid #809AFD; padding:5px 15px; font-size:12px;}
.btn-main {
  height: 35px;
  color: #fff;
  letter-spacing: 1px;
  border: 1px solid #809AFD;
  padding: 5px 30px;
  font-size: 12px;
  background: transparent;
  border-radius: 15px;
}

.finished-row { background: #f2f2f2; border-radius:15px; padding: 10px;  display: flex; align-items: center; margin:15px 0 0;}
.finished-row .icon {
    background: #F3BA2F;
    padding: 4px;
    width: 20px;
    height: 20px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
}
.finished-row.finished p { margin: 0 0 0; color: #fff; font-size:12px;;}
.finished-row .icon img { width: 80%;}
.finished-row button {background: #35c8c2; border: 0; outline: none;}
.finished-row.finished  button{background: #fff; color:#000;}
.finished-row button span { width: 8px;  height: 8px;  border-radius: 100%; background: #000; display: inline-block;}
.finished-amt {display: flex; align-items: center;  justify-content: space-between; margin-top:5px;}
.finished-amt p { font-size: 12px; font-weight: 500;}
.finished-amt p.amt{color:#b5b5b5 }
.tab-row a.active {color: #ffffff;border-bottom: 2px solid #7C6ADD; font-weight: 700;}
.finished-row.finished {background: transparent;border: 1px solid #404881;  padding: 12px 15px; margin-top: 0;}
.fin-right {display: flex;font-size: 10px; align-items: center; margin-left: 10px;}
.fin-right span { color: #809AFD; margin-left: 5px;}
.blue-bg{background:#627EEA !important;}

.deal-row {align-items: center;  margin-bottom:40px;}
.font-18{color:#b5b5b5; font-size:18px; margin-right:10px;}
.mt-0{margin-top:0 !important;}
.total-box { display: flex;  align-items: center;}
.progress {display: flex;height:4px !important; }
.progress-bar {background: linear-gradient(103.59deg, #7C6BDE 0.13%, #A79AF6 59.74%);}
.deal-info ul li {
  list-style: none;
  margin-top: 10px;
  font-size: 12px;
  color: #fff;
  display: flex;
  justify-content: space-between;
  text-align: right;
  letter-spacing: 0.2px;
  font-weight:700;
}

.tire-section-heading {margin-bottom: 15px;}

.col-100{margin-bottom:20px;}
.descr-btn a { color: #35c8c2;  margin-top: 20px;  display: inline-block;}
.dl-dt { margin-top: 10px !important;}
.heading-sm {margin-bottom: 15px; font-weight: 500;}


.login-box {background:#00000014; padding:30px 90px; border-radius:20px; max-width:500px; width:100%;}
.login {width: 100%;min-height: 100vh;display: flex;align-items: center;justify-content: center;background:rgba(46, 53, 96, 1)}
.login-box button {width: 100%;}
.nt-member { font-size: 12px;  margin-top: 5px; color: #747AA8;}
.nt-member a {color: #fff;}
.login-box h1 {font-size:30px; color: #fff;  margin-bottom: 25px;}
.input-design { 
    background-color: #2e3560 !important;
    outline: none;
    border-color: #3d446e !important;
    height: 45px;
    border-radius: 5px !important; outline: none !important;
}
.login button {
    background: transparent !important;
    border: 2px solid #464e82;
    border-radius: 15px;
    outline: none; margin-top:25px;
}

.login label { display: inline-block; color: #747AA8; font-size: 12px;}

.login .form-label { color: #fff; font-size: 12px;}

.user-img img {width: 40px;height: 40px; border-radius: 100%; margin-right:10px;}
.user-img {display: flex; align-items: center; margin-top: 30px;}
.submit-btn  {display: flex;justify-content: flex-end; align-items: center;}
form.deal-form { margin:30px 0 20px; border-radius: 10px;  }
form.deal-form  .form-control { background-color: #2e3560 !important;  outline: none; border-color: #4f5680 !important; height: 40px;}
  .page-heading{font-size: 34px; color: #040404;  margin-bottom: 20px;}

.side-bar {width: 350px;}
.box-container {padding-top:30px;padding-bottom:30px;}
.right-content {width: calc(100% - 600px); padding: 0 30px;}
.left-nav {
  width: 250px;
  background: #292f56;
  padding: 25px 40px;
  border-radius:20px;
}
ul#menu > li svg {margin-right: 18px;}
ul#menu > li:nth-child(3) { margin-bottom: 0;}
.left-nav ul {padding: 0;}
.left-nav ul li a {padding:0; width: 100%;  color: #747AA8; display: inline-block; text-decoration: none; font-size: 13px; letter-spacing:1px;font-weight:700;}
.left-nav label { color: #fff;}
.bar-heading img {border-radius: 10px;}
ul#menu > li{float:left; list-style-type:none; position:relative; width: 100%; margin-bottom:40px;} 
ul#menu label{ position:relative;  display:block;  cursor:pointer;font-size: 13px; color: #747AA8;  font-weight:700; letter-spacing:1px;} 
ul#menu label .fa{margin-right:5px;}
ul#menu label:after{
    content:"";
    position:absolute;
    display:block;
    top:12px;
    right:5px;
    width:0;
    height:0;
    border-top:4px solid rgba(255,255,255,.5);
    border-bottom:0 solid rgba(255,255,255,.5);
    border-left:4px solid transparent;
    border-right:4px solid transparent;
    transition:border-bottom .1s, border-top .1s .1s;
    }
  
    ul#menu  label:hover,
    ul#menu input:checked ~ label{}  
    ul#menu  input:checked ~ label:after{
    border-top:0 solid rgba(255,255,255,.5);
    border-bottom:4px solid rgba(255,255,255,.5);
    transition:border-top .1s, border-bottom .1s .1s;
  }

  ul#menu > li.active label, ul#menu > li.active a {
    color: #fff;
  }

  ul#menu  input{display:none}
  /*show the second levele menu of the selected voice*/
  ul#menu input:checked ~ ul.submenu {padding-left: 15px; max-height: 300px; transition: max-height 0.5s ease-in;border-left: 1px solid #30386d;;margin-bottom: 20px;}
  
  /*style for the second level menu*/
ul.submenu {margin-top:40px; max-height: 0;   overflow: hidden; list-style-type: none; box-shadow: 0 0 1px rgb(0 0 0 / 30%);transition: max-height 0.5s ease-out;min-width: 100%;}
ul.submenu li a {
  display: block;
  padding: 12px 0 15px 15px;
  color: #747AA8;
  text-decoration: none;
  transition: background .3s;
  white-space: nowrap;
  border-radius: 10px; letter-spacing:1px; font-weight:60;
}

ul.submenu li a.active {background: #2e3560;}

.bar-heading {display: flex; justify-content:space-between; align-items: center; flex-wrap: wrap;}
.bar-heading h3 {font-size:13px;color: #747AA8; font-weight:700;}
.bar-box {background:#292f56;padding:20px;border-radius: 20px; margin-bottom:20px; position: relative;}
.bar-box:last-child {margin-bottom:0;}
.content-box {background: #30386D; padding: 20px; border-radius: 20px;}
.right-content h1 { color: #fff; font-size:18px; margin-bottom: 0; font-weight:500; }
.heading-row {display: flex; align-items: center;}
.bc-btn {width: 25px; height: 25px; display:flex; background: #00000024; border-radius: 100%;color: #fff;align-items: center;margin-right: 10px; justify-content: center;}
.chart-box {border: 1px solid #404881;border-radius:10px;padding: 20px;text-align: center;}
.chart-box-content p { font-size: 12px; color: #747AA8;margin-bottom: 0;}
p.price-chart {font-size:16px; font-weight: 700; color: #fff;}
.chart-box-content {margin-top: 10px;}
p.pr-percent { color: #fff;  font-size: 13px;  margin-bottom: 5px;}
.chart-row { padding: 25px 0 20px;}
.tire-section-heading span {
    background: #fff;
    padding:4px 10px;
    display: inline-block;
    border-radius: 0 0 10px 10px;
    font-size: 12px;
    font-weight: 700;
    color: #2A3058;
}

.line-chart {position: relative; margin: 0 -20px -20px;}

.deal-info ul {padding-left: 0;}
.minus-plus-row {
  display: flex;
  align-items: center;
  width: 100%;
  background: #292f56;
  padding: 0 10px;
  border-radius: 15px;
  justify-content: space-between;
  height: 35px;
}

.right-str {display: flex; margin-bottom:10px;}
.right-str .text-heading {margin-right: 25px;}

.minus-plus-row button { background: transparent; outline: none;  border: 0;  color: #747AA8;  font-size: 20px;}
.minus-plus-row p { margin-bottom: 0;  color: #fff;  font-size: 13px;   font-weight: 700;}
.minus-plus-row .badge { background: #747AA8;  font-size: 10px; margin-right:5px;}
.plus-btn {display: flex; align-items: center;}
.cnt-button {
   height:35px; font-weight:700; font-size:13px !important;margin-bottom: 10px;
   background: transparent !important;width: 100%;
   border: 0 !important; position: relative; letter-spacing: 1px;
  
  }


  .cnt-button:before{

    content:"";
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    border-radius:15px; 
    padding:2px; 
    background:linear-gradient(103deg, #7F67DB,#607DEA); 
    -webkit-mask: 
       linear-gradient(#fff 0 0) content-box, 
       linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out; 
    mask-composite: exclude; 
  }


  .orange-button { height:35px; font-weight:700; font-size: 13px !important; background: transparent !important;
    border: 0 !important; position: relative;      padding: 5px 30px; letter-spacing: 1px;
   } 
 
   .orange-button:before{
      content:"";
     position:absolute;
     top:0;
     left:0;
     right:0;
     bottom:0;
     border-radius:15px; 
     padding:2px; 
     background:linear-gradient(98.6deg, #EEAD31 0%, #FFD482 94.77%);
     -webkit-mask: 
        linear-gradient(#fff 0 0) content-box, 
        linear-gradient(#fff 0 0);
     -webkit-mask-composite: destination-out; 
     mask-composite: exclude; 
   }


  .red-button { height:35px; font-weight:700; font-size: 13px !important; background: transparent !important;
    border: 0 !important; position: relative;       padding: 5px 30px;letter-spacing: 1px;
   } 
 
   .red-button:before{
      content:"";
     position:absolute;
     top:0;
     left:0;
     right:0;
     bottom:0;
     border-radius:15px; 
     padding:2px; 
     background:linear-gradient(99.05deg, #D66C4A 0%, #C76343 88.57%);    
     -webkit-mask: 
        linear-gradient(#fff 0 0) content-box, 
        linear-gradient(#fff 0 0);
     -webkit-mask-composite: destination-out; 
     mask-composite: exclude; 
   }

.cnt-button.cancel-btn:before
{ content:"";
  position:absolute;
  top:0;
  left:0;
  right:0;
  bottom:0;
  border-radius:15px; 
  padding:2px; 
  background:linear-gradient(99deg, #D66C4A,#EF8A69); 
  -webkit-mask: 
     linear-gradient(#fff 0 0) content-box, 
     linear-gradient(#fff 0 0);
  -webkit-mask-composite: destination-out; 
  mask-composite: exclude; 
}
.buy-btn-row button:hover { background: linear-gradient(91.53deg, #7C6ADD 2.89%, #617EEB 97.48%) !important; border-radius:15px;}
.cnt-button:hover { background: linear-gradient(91.53deg, #7C6ADD 2.89%, #617EEB 97.48%) !important; border-radius:15px;}
.cnt-button.cancel-btn:hover { background:linear-gradient(99deg, #D66C4A,#EF8A69) !important;  border-radius:15px;}



.gr-button{color:#fff;border:0; background: linear-gradient(91.53deg, #7C6ADD 2.89%, #617EEB 97.48%) !important; 
  height: 35px;
  letter-spacing: 1px;
  border: 1px solid #809AFD;
  padding: 5px 30px;
  font-size: 12px;
  border-radius: 15px;
}


button.cnt-button.cancel-btn { border-color: #df8223 !important;}
.qust {text-align: center;font-size: 12px; color: #fff; position: relative; margin:10px 0 0  ;}
.qust .badge {background: #fff;color: #282e53;border-radius: 100%;height: 16px;width: 16px; margin: 0 0 0 5px;position: relative;top: -5px;}
.tire-section {border-top: 1px solid #404881; margin-left: -20px; margin-right: -20px;padding: 0 5px; border-bottom: 1px solid #404881;margin-bottom: 20px;}
.custom_radio input[type="radio"]{display: none;  }
.tire-section .cnt-button:first-child {margin-bottom: 25px;}
  .custom_radio input[type="radio"] + label{
    position: relative;
    display: inline-block;
    padding-left: 1.5em;
    margin-right: 2em;
    cursor: pointer;
    line-height: 1em;
    transition: all 0.3s ease-in-out;
  }
  .custom_radio input[type="radio"] + label:before,
  .custom_radio input[type="radio"] + label:after{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 1em;
    height: 1em;
    text-align: center;
    color: white;
    font-family: Times;
    border-radius: 50%;
    transition: all .3s ease;
  }

  .number-cnt {position: absolute; display: flex; bottom: 25px;  width: 100%;}
  .numb-p {flex: 1 1;  text-align: center;  font-size: 12px;   color: #fff;}
  .select-point {width: 7px;position: absolute;display: flex;justify-content: center;flex-wrap: wrap;right: 96px;top: 64px;}

.custom_radio input[type="radio"] + label:before { transition: all .3s ease; box-shadow: inset 0 0 0 0.2em  #282e53, inset 0 0 0 1em #282e53;}
.custom_radio input[type="radio"] + label:hover:before {
    transition: all .3s ease;
    box-shadow: inset 0 0 0 0.3em #282e53, inset 0 0 0 1em #c6c6c6;
  }

.custom_radio input[type="radio"]:checked + label:before {transition: all .3s ease;box-shadow: inset 0 0 0 0.2em #282e53, inset 0 0 0 1em #4753a3;}
.custom_radio { display: flex;  align-items: center;}
.buy-section button { margin: 0;}
.buy-section {  font-size: 12px;  color: #fff;   padding: 20px 0;}
.bottom-content p { color: #747AA8;  font-size: 12px;  margin-bottom: 10px;}
.descr-btn a { color:#747AA8;  font-size: 12px;}

.bar-cnt {text-align: center;  margin: 20px 0 10px;}
.bar-cnt h3 {font-size: 50px; font-weight: 300; color: #fff; margin: 0;}
.bar-cnt p { color: #747AA8;  font-size: 12px;  margin-bottom: 0;}
.tm {margin-right: 20px;}
.tm .fa {color: #7C6BDE; font-size: 8px;}
.nc .fa {color:#617EEB; font-size: 8px;}
.amount .fa { color: #52B5BC;  font-size: 8px;}

.all-deal-box .user-name-row.dl-dt {margin-top: 0 !important;}
.all-deal-box .user-name-row img {height: 40px;width: 40px;object-fit: cover;border-radius: 15px;}
.all-deal-box .user-name-row p {margin-bottom: 5px;}
.all-deal-box {background: #30386D; padding: 20px; border-radius: 20px; box-shadow: 0px 4px 100px rgba(33, 39, 76, 0.7); margin-bottom:40px;}
.all-deal-box .chart-box {display: flex;justify-content: space-around;padding: 10px 10px;align-items: center;}
.all-deal-box .chart-box .chart-box-content {margin-top: 0;}
.all-deal-box  p.price-chart .fa {color: #8169DD; font-size: 8px;}
.all-deal-box .chart-box .progress-bar-row {width:50%;}
.header-chart {margin-bottom: 20px;}
.btn-main:hover { background: linear-gradient(91.53deg, #7C6ADD 2.89%, #617EEB 97.48%);}
.yellow-text{color: #EBB324 !important;}
.all-deal-box .user-name-row .pr-percent {font-size: 15px;font-weight: 700;}
.overlay-popup {width: 100%;height: 100%;position: fixed;top: 0; left: 0;  display: flex;  align-items: center;  justify-content: center;}
.popup-box { max-width: 500px; width: 100%;  background: linear-gradient(111.02deg, #30386D 7.43%, #3B427A 97.77%); border-radius: 20px; position: relative;}
.popup-body {padding: 30px 80px 0;}
.popup-header h2 {font-weight: 500;color: #fff;text-align: center;font-size: 30px;margin-bottom: 30px;}
.popup-header p {color: #747AA8;font-size: 12px;text-align: center;}
.popup-body .form-label {color: #fff;font-size: 12px; letter-spacing: 1px;}

.popup-box button {width: 100%;font-size: 12px;background: transparent !important;border: 2px solid #58619d !important;border-radius: 15px; margin-top:10px;}
.footer-popup {border-top: 1px solid #404881; margin-top: 30px;padding: 10px 20px 20px;}
.footer-popup p {margin-bottom: 0; font-size: 12px;  color: #747AA8;  text-align: center;}
.closed {color: #fff;position: absolute;right: 26px;top: 10px; font-size: 24px; cursor: pointer;}
.form-control { background-color: #2e3560 !important;outline: none;border-color: #3d446e !important;height: 45px;border-radius: 5px !important; outline: none !important;}
.deal-form .form-label {font-size: 12px; color: #fff;  letter-spacing: 1px;}

@media (min-width: 1200px){
.container, .container-lg, .container-md, .container-sm, .container-xl { max-width: 1440px !important;}
}
p{letter-spacing: 1px;font-weight: 300;}
*{margin: 0; color:#fff}
.logo-homepage {height: 45px;}
.navbar-light .navbar-brand {color: #fff !important; display: flex; align-items: center;}
.navbar-collapse {flex-grow: initial !important;}
.navbar-light .navbar-nav .nav-link {color: #747AA8 !important;}
a.navbar-brand span {font-size: 14px; margin: 18px 0 0 10px;}
p.best-deal { color: #fff !important; letter-spacing: 5px; font-size: 16px; margin: 0; font-weight: 300; text-transform: uppercase;}
.banner-content h1 {font-weight: 700; color:#fff; font-size:80px;}
.banner-content h1 span {background: -webkit-linear-gradient(91.53deg, #7C6ADD 2.89%, #617EEB 97.48%) ;  -webkit-background-clip: text; -webkit-text-fill-color: transparent;}
.buy-btn-row p {margin-bottom: 0;margin-right: 10px;color: #fff; font-weight: 700;}
.buy-btn-row {display: flex; align-items: center;margin-top: 60px;}
.buy-btn-row button {margin-right: 10px; margin-bottom: 10px;}
.banner {width: 100%;display: flex;align-items: center;padding: 100px 0; position: relative; margin-top:50px;}
.banner-content .content {width:435px;}
.banner-content p {color: #747AA8;}
.buy-btn-row p {color: #fff;}
img.banner-img {position: absolute; right: 0;  width:65%; z-index:-1;}
.banner svg {position: absolute; width: 25%; z-index: -1;}
.gr-button{margin-top: 10px;}  

.axiforma-font{
  font-family: 'Axiforma', sans-serif;
}
.chart-box{
  margin-bottom: 10px;
}

.main-page-heading {text-align: center; width: 100%;}
.main-page-heading p {letter-spacing: 5px; font-size: 10px; color:#fff; text-transform: uppercase;}
.main-page-heading h2 {font-weight: 700; font-size:50px;color: #e8e8e8;}
.main-page-heading {text-align: center; width: 100%; margin: 0 0 50px;}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
  color: #fff !important; background-color: transparent !important;  border: 0;  border-bottom:0 !important;}
.nav-tabs { border-bottom: 0 !important;   text-align: center;   justify-content: center;}
.nav-link{color:#747AA8 !important; margin-bottom:0 !important; border:0 !important; position: relative;}
.nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover { border:0 !important; color:#fff }
.nav-link.active:after  {border-bottom:1px solid red; bottom: 0; position:absolute; content:''; width:100%;}
.nav-link.active:after {border-bottom: 2px solid #747AA8;bottom: 0;position: absolute; content: ''; width: 70%; left: 0; right: 0; margin: auto;}
header .navbar-light .navbar-nav .nav-link {color: #747AA8 !important; font-size: 13px; letter-spacing: 1px;}


.all-deal-box.home-deal-box img{width: auto !important;}
.deal-box-inner {border: 1px solid #404881; border-radius: 10px;}
.about-joindeal p {margin: 0; font-size:13px;  text-align: center; width:50%;background: linear-gradient(91.95deg, #8169DD 0%, #5374EB 98.35%);  -webkit-background-clip: text; -webkit-text-fill-color: transparent;}
.brdr-ab {border-right: 2px solid #404881;}
.about-joindeal {display: flex;justify-content:center;padding: 15px 0;    background: linear-gradient( 176.07deg , #2A2E58 4.63%, #31396E 91.28%);}
.cnt-box {padding: 20px;}
.all-deal-box.home-deal-box {padding: 0;}
.all-deal-row { display: flex;}
.tab-content { margin-top: 50px;}
.all-deal-box.home-deal-box {padding: 0;box-shadow: 0px 4px 22px rgb(33 39 76 / 70%); margin:0 10px;}
.all-deal-box{border-radius:20px; overflow: hidden;}

.project-section {padding: 100px 0;}
.bulk-box .content p {font-size:18px;text-align: center; margin: 0 0 0; letter-spacing: 1px; font-weight:bold;}

.media-production {padding: 100px 0;}
.support-box .content {background:rgb(54 62 107); box-shadow: 0px 4px 40px rgb(0 0 0 / 12%); border-radius: 10px; padding: 20px;}
.support-box .content p {font-size: 14px; line-height: 26px;color: #747AA8; margin: 0;}
.support-box .content h5 {font-size: 18px; letter-spacing: 1px; margin: 20px 0 12px;}
.support-row {display: flex;flex-wrap: wrap; margin: -15px;}
.support-box {width: 50%; padding: 0 15px;}
.partnership-box {margin-top:70px;min-height: 250px; display: flex;}

.market-media-cnt .content .main-page-heading {text-align: left;}
.market-media-cnt .bottom-content p {color: #747AA8; font-size: 16px; margin: 0;}
.market-media-cnt .content .main-page-heading {text-align: left;  margin-bottom: 30px;}
.market-media-cnt .content {padding-left: 50px; position: relative;}

.media-left-graph-bg {position: absolute;width: 60%; z-index: -1;}
.media-left-graph-bg img {width: 100%;}
.svg-mp {position: absolute;}

.financial-box {display: flex;border: 1px solid #747AA8;padding: 40px 50px;align-items: center;border-radius: 10px;width: 75%; margin: auto;}
.financial-box .content p {margin: 0; font-size: 18px; letter-spacing: 1px; line-height: 32px;}
.financial-box .content {width: calc(100% - 55px); padding-left: 30px;}
.financial-seciton {position: relative;}
.financial-seciton img.right-ln {right: 0;position: absolute;width: 30%;  top: 61px;}

.technical-support {padding: 100px 0;}
.tech-support-box { background: rgba(128, 134, 207, 0.1);
  box-shadow: 0px 4px 40px rgb(0 0 0 / 12%); -webkit-backdrop-filter: blur(10px); backdrop-filter: blur(10px);
  border-radius: 10px; padding: 25px 40px; position: relative; display: flex; align-items: center; margin:0 0 30px;}
.tech-support-box .icon {background: #2E3560;position: absolute;left: -21px;width: 40px;height: 40px; align-items: center;display: flex; justify-content: center; border-radius: 100%;}
.tech-support-left .content p {color: #747AA8;}
.tech-support-box.sup-border {background: transparent; border: 1px dotted #6f759b;}
.tech-support-box.sup-border .icon{border: 1px dotted #6f759b;}
.tech-support-box.sup-border .icon:after {
  content: '';
  width: 32px;
  position: absolute;
  height: 56px;
  border: black;
  left: -13px;
  display: inline-block;
  top: -14px;
  background: #2e3560;
}

.tech-support-box.sup-border svg{position: relative; z-index: 9;}
.tech-support-box .content p {color: #747AA8; font-size: 14px; line-height: 26px; letter-spacing: 1px; margin: 0;}
.tech-support-left .main-page-heading {text-align: left;}
.tech-support-left .content {padding-right: 70px;}
.advisory-box { border-radius: 10px; display: flex;
  border: 1px solid #4f5780;
  align-items: center; padding: 20px 30px; min-height:130px;}
.advisory-box:hover { background: rgba(128, 134, 207, 0.1);  box-shadow: 0px 4px 50px rgb(0 0 0 / 12%); border: none; }
.advisory-box .content {padding-left:30px;}
.advisory-box .content p{color:#fff; margin:0; font-size:18px; line-height:26px; letter-spacing:1px;}

.accordion-item {background:rgb(60 67 114) !important;box-shadow: 0px 4px 40px rgb(0 0 0 / 12%) !important;border-radius: 10px !important; margin:0 0 15px;}
button.accordion-button.collapsed {
  background: rgba(128, 134, 207, 0.1);
  box-shadow: 0px 4px 40px rgb(0 0 0 / 12%);
  border-radius: 10px !important;
  color: #fff;
  font-size: 18px;
}
.accordion-body { font-size: 14px; color: #747AA8; line-height: 26px;}
.accordion-button:not(.collapsed) {color: #fff !important;background-color: transparent !important;box-shadow: none !important; font-size: 18px;}
section.faq-section {padding: 100px 0; position: relative;}
.fc {position: absolute;right: 0; width: 50%; z-index:-1;}
.faq-section .bg-faq {position: absolute; z-index: -1; top: 25%; right: 0; width: 50%;}
.faq-content {width: 75%; margin: auto; font-family: 'Axiforma', sans-serif;}

.footer-contain {background:#21244c;display: flex;justify-content:space-between; border-radius: 50px;align-items: center;padding: 20px 20px;}
.footer-left ul {display: flex; padding: 0; margin: 0;}
.footer-right ul {display: flex; padding: 0; margin: 0;}
.footer-left ul li a {list-style: none;padding-right: 20px;font-size: 12px; color: #747AA8;}
.footer-right ul li  {list-style: none;padding-left: 20px;    display: flex; align-items: center;}
.footer-right ul li p {font-size: 12px; color: #747AA8; margin:0 0 0 ;} 
.footer-right {display: flex; align-items: center;}
.bottom-footer {font-size: 12px;text-align: center;color: #747AA8; font-weight: normal; margin:30px 0;   letter-spacing: 1px;}
.footer-left li {list-style: none;}
.footer-right li svg {height:15px;}

.book-slider-main:before { position: absolute; background: linear-gradient(270deg, rgba(46, 52, 95, 0) -68.04%, #2E3560 82.47%);  left: 0;
  content: '';  width: 20%;  height: 100%;  z-index: 9;
}
.book-slider-main:after { position: absolute; background:linear-gradient(90deg, rgba(46, 52, 95, 0) -68.04%, #2E3560 82.47%);  right: 0;
  content: '';  width: 20%;  height: 100%;  z-index: 9; top:0;
}
.book-slider-main{position: relative;}

.partner-section {position: relative;padding-bottom: 100px;}
.partner-section .bg {position: absolute;left: 0;top: -50px;width: 33%;z-index: -1;}
.partner-slider:before {
  top: 0;  position: absolute;
  background: linear-gradient(270deg, rgba(46, 52, 95, 0) -68.04%, #2E3560 98.47%);
  left: 0;  content: '';  width: 48%;  height: 100%;  z-index: 9;
}
.partner-slider:after {top:0; position: absolute; background:linear-gradient(90deg, rgba(46, 52, 95, 0) -68.04%, #2E3560 82.47%);  right: 0;content: '';  width:37%;  height: 100%;  z-index: 9; top:0;}
.partner-slider .item img {width:50% !important; margin: auto;}
.partner-slider {margin-top: 100px;}
.partner-section .main-page-heading {position: relative;z-index: 99;}
.partner-section  .owl-carousel.owl-drag .owl-item { height: 150px;align-items: center;display: flex;}

.bulk-graph { width: 100%; display: flex; align-items: center; justify-content: center; margin:0 0 20px;}
.bulk-graph p {position: absolute;color: #607DEA;font-size: 36px; font-weight: 700; letter-spacing: 1px;}
.orange-clr{ color:#FFD584 !important;} 

.book-slider-main .owl-nav {display: block !important; position: absolute; left: 0; right: 0; bottom: -80px;}
.book-slider-main  .owl-carousel .owl-nav button.owl-prev{background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADQAAAAKCAYAAAD7CH02AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACqSURBVHgB3ZXBCcJQDIb/WAfoPMUuoYg4hR7qQRF8vfTUg1Moij24gaIUj45iF3gxUarWDdIP3uPP8SMhAf6YLfeTZL6Zwiid3yJZ7Fbs/RpEIYxCdVAZgJ28NM/GDkZ5CbVFRqGvDB5g3GEaf+yCuBIRJZRMsAx/Rm7rJEqnyOXZKIVhAv3KS3GK4oHKuSgeSn04wyhBHRpSvX5VXosbDNK4Q+8Nx6ZH7gl5pT49HJAaCAAAAABJRU5ErkJggg==); width:52px;background-repeat: no-repeat !important; height:10px;}
.book-slider-main  .owl-carousel .owl-nav button.owl-next{background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADQAAAAKCAYAAAD7CH02AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACiSURBVHgB1ZTBCcJAEEX/xBSQegIBSxDEgzYhAb2I4HrRSw62oWgPSiBYjhbgjjPxIksa+A92h/m3BzMDkLLeXparzXmX5hlIUY1if0ilRiCla6/PspqaFEJZzbx/eE4r5HTt7Z5Kic+ifuIEzAjGfdVY5/0sigqYkexlS1VApOAWMX5HQYO9fXOYB2qhVMYzWqEhGScHK6JvxFg3x8XpP/4ChyNFQ/wpEsgAAAAASUVORK5CYII=);width:52px; background-repeat: no-repeat !important; height:10px;}
.book-slider-main  .owl-carousel .owl-nav button span{display: none;}
.book-slider-main  .owl-carousel .owl-stage{padding:20px 0;}
.book-slider-main .owl-dots {display: none;}

.see-all-buttn button {width: auto; padding: 5px 65px;height: 45px; margin: 0; position: relative; z-index:999;}
.book-slider-main {width: 100%; text-align: center;}
.book-slider-main .owl-carousel .owl-nav button {margin: 0 120px;}
.see-all-buttn {margin-top: 44px;}
button.btn-main.hrd {  margin-left: 15px;}

.navbar-light .navbar-toggler {color: rgb(119 104 214); border-color: rgb(108 126 229) !important; border-width:2px;}
.navbar-light .navbar-toggler-icon {filter: brightness(0) invert(1);}
.accordion-button::after {opacity: 0.6 !important;}

@media (min-width: 1200px){
  .container, .container-lg, .container-md, .container-sm, .container-xl { max-width: 1140px !important;}
  }

@media screen and (max-width: 1199px)
{
.banner{margin-top:0;}
.svg-mp {z-index: -1; left: 0;}
.partner-slider:before, .partner-slider:after{display: none;}
.book-slider-main:after, .book-slider-main:before{display: none;}
.faq-content {width:100%;}
.financial-box{width:100%;}
}  

@media screen and (max-width:991px)
{
.banner{margin-top:0; padding: 50px 0 0;}
.container, .container-md, .container-sm { max-width: 90% !important;}
img.banner-img { width: 60%;}
.book-slider-main .owl-dots {display: none;}
.media-production {padding: 50px 0;}
.project-section {padding: 50px 0;}
.partner-slider {  margin-top: 50px;}
.footer-contain{flex-wrap: wrap;}
.me-auto.navbar-nav {background: #363e6b;padding: 20px;border-radius: 15px;position: absolute;z-index: 999;width: 90%; left: 0; right: 0;margin: auto;  top: 75px;}
button.btn-main.hrd { margin-left: 15px; display: none;}
.nav-link.active:after{display: none;}
.navbar-toggler:focus{box-shadow: none !important;}
}  

@media screen and (max-width: 767px)  
{
img.banner-img{display: none;}  
.banner-content .content { width: 100%; text-align: center;}
.buy-btn-row { margin-top:30px;flex-wrap: wrap; justify-content: center;}
.buy-btn-row p {width: 100%; margin-bottom:20px;}
.buy-btn-row button { min-width: 150px;}
.bulk-box {margin-bottom: 40px;}
.support-box {width: 100%; text-align: center;}
.partnership-box {margin-top: 30px;min-height: auto;}
.market-media-cnt {margin-top: 50px;}
.market-media-cnt .content .main-page-heading {text-align: center;}
.market-media-cnt .content {padding-left: 0px; position: relative;}
.market-media-cnt .bottom-content p{text-align: center;}
.financial-box .icon {width: 100%;text-align: center; margin-bottom: 20px;}
.financial-box {flex-wrap: wrap; justify-content: center;}
.financial-box .content {width: 100%; padding-left: 0; text-align: center;}
.tech-support-left .content {padding-right: 0;text-align: center;}
.tech-support-left .main-page-heading {text-align: center;}
.technical-support { padding: 50px 0;}
.main-page-heading {margin: 0 0 30px;}
.main-page-heading h2 {font-size: 32px;}
.advisory-box {margin: 0 0 30px;}
.advisory-box .icon {width: 100%; text-align: center; margin-bottom: 20px;}
.advisory-box {flex-wrap: wrap; justify-content: center; margin: 0 0 30px;}
.advisory-box .content {padding-left: 0; width: 100%; text-align: center;}
section.faq-section { padding:50px 0;}
.partner-section {padding-bottom: 50px;}
.footer-left {width: 100%;display: inline-block;text-align: center;}
.footer-left ul {display: flex;width: 100%; justify-content: center; margin-bottom: 10px;}
.footer-right{width:100%; flex-wrap: wrap; }
.footer-right ul {width: 100%;justify-content: center; margin:10px 0 0;}
.book-slider-main .owl-nav {position: relative; bottom: 0;}
.book-slider-main .owl-carousel .owl-nav button {margin: 0 30px;}
.banner-content h1 {font-weight: 700; color: #fff;  font-size: 44px;}
}

/*Custom accordion for FAQ */
.accordion {
  margin-top: 15px;
  padding: 18px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  transition: 0.4s;
  background: #3c4372!important;
  box-shadow: 0 4px 40px rgba(0,0,0,.12)!important;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.show{
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.accordion:after {
  content: '\002B';
  color: #fff;
  font-weight: bold;
  float: right;
  margin-left: 5px;
}

.show:after {
  content: "\2212";
}

.panel {
  padding: 0 18px;
  background-color: #3c4372;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.panel p{
  color: #747aa8;
  font-size: 14px;
  line-height: 26px;
}

.white-btn {height:35px;font-size:13px !important;background: transparent !important; border: 0 !important; 
  position: relative; letter-spacing: 1px; padding: 5px 30px; color: #fff;}
  .white-btn:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 15px;
    padding: 2px;
    background: linear-gradient(103deg, #e3dbff,#607DEA);
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out;
    mask-composite: exclude;
}
